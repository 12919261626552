<template>
  <div class="remove-container">
    <div class="result-card">
      <image-dynamic-effects :modelFilepath="modelFilepath" :imageSize="276" :warmupModel="warmupModel"
        :preprocess="preprocess" :postprocess="postprocess" :currentFileList="currentFileList" />
    </div>
  </div>
</template>

<script setup>
import ImageDynamicEffects from "@/components/models/imageDynamicEffects.vue";
import { useMeta } from 'vue-meta'
useMeta({
  title: 'Utilize the power of animation to create photos - Boolv.Toolkit',
  description: "Turn monotonous photos into animated photos in just one click. No editing skills, codes, or complicated procedures are required. Make your pics vivid online for free now.",
  htmlAttrs: { lang: 'en', amp: true }
})
</script>

<style lang="less"></style>
