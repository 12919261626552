<template>
  <div class="back-btn-box" @click="handleBack">
    <img :src="iconArrow" class="icon-left-arrow" />
    <p class="text">Back</p>
  </div>
</template>

<script setup>
import iconArrow from "@/assets/common-icons/icon_arrow.svg";
import { defineEmits } from "vue";

const emits = defineEmits(["back"]);

const handleBack = () => {
  emits("back");
};
</script>

<style lang="less">
.back-btn-box {
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon-left-arrow-box {
    width: 13.5px;
    height: 16px;

    .icon-left-arrow {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    font-weight: 500;
    margin-left: 22.5px;
    font-size: 14px;
    font-weight: 500;
    color: #060606;
  }
}
</style>
