import { message } from "ant-design-vue";
import close_icon from "@/assets/close_icon.svg";
import "./index.less";

//
// content String message内容
// duration Number 持续多久(默认3s，loading状态下传0不会消失，直到手动关闭)
// buttonText String 右侧的按钮文案，没有就不传
// buttonCb function 和buttonText 绑定传递，没有就不传
// close boolean 关闭按钮，没有不传
// closeCb function 和close 绑定 ，没有不传

message.config({
  top: `64px`,
  duration: 3,
  maxCount: 1,
});

const MessageSuccess = (content, buttonText, buttonCb, close, closeCb) => {
  message.success({
    content: () => {
      // 这里是自定义插槽的渲染函数
      return (
        <div class="message-box">
          <div>{content}</div>
          {buttonText && (
            <div class="message-button" onClick={buttonCb}>
              {buttonText}
            </div>
          )}
          {close && (
            <img src={close_icon} onClick={closeCb} class="close-icon" />
          )}
        </div>
      );
    },
    class: "message-success",
  });
};

const MessageError = (content, duration = 3, buttonText, buttonCb, close, closeCb) => {
  message.error({
    duration: duration,
    content: () => {
      // 这里是自定义插槽的渲染函数
      return (
        <div class="message-box">
          <div>{content}</div>
          {buttonText && (
            <div class="message-button" onClick={buttonCb}>
              {buttonText}
            </div>
          )}
          {close && (
            <img src={close_icon} onClick={closeCb} class="close-icon" />
          )}
        </div>
      );
    },
    class: "message-error",
  });
};

const MessageWarning = () => {
  message.warning("This is a warning message");
};

const MessageLoading = (
  content,
  duration,
  buttonText,
  buttonCb,
  close,
  closeCb
) => {
  message.loading({
    class: "message-loading",
    duration: duration,
    content: () => {
      // 这里是自定义插槽的渲染函数
      return (
        <div class="message-box">
          <div>{content}</div>
          {buttonText && (
            <div class="message-button" onClick={buttonCb}>
              {buttonText}
            </div>
          )}
          {close && (
            <img src={close_icon} onClick={closeCb} class="close-icon" />
          )}
        </div>
      );
    },
  });
};

const MessageCustom = (
  content,
  buttonText,
  buttonCb,
  close,
  closeCb,
  customStyle
) => {
  message.info({
    content: () => {
      // 这里是自定义插槽的渲染函数
      return (
        <div class="message-box">
          <div>{content}</div>
          {buttonText && (
            <div class="message-button" onClick={buttonCb}>
              {buttonText}
            </div>
          )}
          {close && (
            <img src={close_icon} onClick={closeCb} class="close-icon" />
          )}
        </div>
      );
    },
    style: customStyle,
    duration: 20,
    visible: true,
  });
};

const destroy = () => {
  message.destroy();
};
export {
  MessageSuccess,
  MessageError,
  MessageLoading,
  MessageWarning,
  destroy,
  MessageCustom,
};
